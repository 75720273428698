import React, { useEffect, useState } from "react";
import {
  BookOpen,
  AlertCircle,
  CheckCircle,
  XCircle,
  AlertTriangle,
  X,
} from "lucide-react";

export interface AlertProps {
  containerClassName?: string;
  type?: "default" | "warning" | "info" | "success" | "error";
  children: React.ReactNode;
  duration?: number;
  showProgress?: boolean;
  persistent?: boolean;
}

export const Alert: React.FC<AlertProps> = ({
  children = "Alert Text",
  containerClassName = "",
  type = "default",
  duration = 7000,
  showProgress = true,
  persistent = false,
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const [progress, setProgress] = useState(100);

  // Map alert types to styles and icons
  const alertStyles = {
    default: {
      container:
        "border-l-4 border-indigo-300 bg-indigo-50 text-indigo-800 dark:bg-gray-800 dark:border-indigo-500 dark:text-indigo-200",
      icon: <BookOpen className="w-5 h-5" />,
      button: "hover:bg-indigo-100 dark:hover:bg-gray-700",
      progress: "bg-indigo-300 dark:bg-indigo-500",
    },
    info: {
      container:
        "border-l-4 border-blue-300 bg-blue-50 text-blue-800 dark:bg-gray-800 dark:border-blue-500 dark:text-blue-200",
      icon: <AlertCircle className="w-5 h-5" />,
      button: "hover:bg-blue-100 dark:hover:bg-gray-700",
      progress: "bg-blue-300 dark:bg-blue-500",
    },
    success: {
      container:
        "border-l-4 border-green-300 bg-green-50 text-green-800 dark:bg-gray-800 dark:border-green-500 dark:text-green-200",
      icon: <CheckCircle className="w-5 h-5" />,
      button: "hover:bg-green-100 dark:hover:bg-gray-700",
      progress: "bg-green-300 dark:bg-green-500",
    },
    error: {
      container:
        "border-l-4 border-red-300 bg-red-50 text-red-800 dark:bg-gray-800 dark:border-red-500 dark:text-red-200",
      icon: <XCircle className="w-5 h-5" />,
      button: "hover:bg-red-100 dark:hover:bg-gray-700",
      progress: "bg-red-300 dark:bg-red-500",
    },
    warning: {
      container:
        "border-l-4 border-yellow-300 bg-yellow-50 text-yellow-800 dark:bg-gray-800 dark:border-yellow-500 dark:text-yellow-200",
      icon: <AlertTriangle className="w-5 h-5" />,
      button: "hover:bg-yellow-100 dark:hover:bg-gray-700",
      progress: "bg-yellow-300 dark:bg-yellow-500",
    },
  };

  const {
    container,
    icon,
    button,
    progress: progressColor,
  } = alertStyles[type];

  useEffect(() => {
    if (persistent) return;

    const progressInterval = setInterval(() => {
      setProgress((prev) => {
        if (prev <= 0) return 0;
        return prev - 100 / (duration / 100);
      });
    }, 100);

    const timer = setTimeout(() => {
      setIsVisible(false);
    }, duration);

    return () => {
      clearTimeout(timer);
      clearInterval(progressInterval);
    };
  }, [duration, persistent]);

  if (!isVisible) return null;

  return (
    <div className="relative">
      <div
        className={`flex items-center p-4 rounded-lg shadow-md ${container} ${containerClassName} transform transition-all duration-300 ease-in-out hover:scale-[1.02] hover:shadow-lg`}
        role="alert"
      >
        <div className="flex-shrink-0 animate-bounce-gentle">{icon}</div>
        <div className="ms-3 text-sm font-medium">{children}</div>
        {!persistent && (
          <button
            type="button"
            onClick={() => setIsVisible(false)}
            className={`ms-auto -mx-1.5 -my-1.5 rounded-lg p-1.5 inline-flex items-center justify-center h-8 w-8 transition-all duration-200 ${button} hover:rotate-90`}
            aria-label="Dismiss"
          >
            <span className="sr-only">Dismiss</span>
            <X className="w-4 h-4" />
          </button>
        )}
      </div>
      {showProgress && !persistent && (
        <div className="absolute bottom-0 left-0 w-full h-1 bg-gray-200 rounded-b-lg overflow-hidden">
          <div
            className={`h-full transition-all duration-100 ${progressColor}`}
            style={{ width: `${progress}%` }}
          />
        </div>
      )}
    
    </div>
  );
};

export default Alert;
