import React, { useState, useEffect } from 'react';
import { Dialog, Transition, Popover } from '@headlessui/react';
import { Fragment } from 'react';
import LoadingIcon from 'components/Loading/LoadingIcon';

const images = [
    '/tutorial-1.png',
    '/micTutorial.png',
    '/tutorial-2.png',
    '/tutorial-3.png',
];

const TutorialPopover: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = images[currentIndex];
    img.onload = () => setLoading(false);
  }, [currentIndex]);

  const nextSlide = () => {
    if (currentIndex < images.length - 1) {
      setLoading(true);
      setCurrentIndex(currentIndex + 1);
    }
  };

  const previousSlide = () => {
    if (currentIndex > 0) {
      setLoading(true);
      setCurrentIndex(currentIndex - 1);
    }
  };

  const isFirstSlide = currentIndex === 0;
  const isLastSlide = currentIndex === images.length - 1;

  return (
    <Popover>
      <Transition
        show={true}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Popover.Panel static className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="relative bg-white p-4 lg-p-8 rounded-lg shadow-lg max-w-4xl lg-w-full">
            {loading ? (
              <div className="w-full h-full flex items-center justify-center">
                <LoadingIcon />
                <span className="text-gray-500">Loading...</span>
              </div>
            ) : (
              <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} className="w-full h-full object-cover mb-4" />
            )}
            <div className="flex justify-between space-x-4">
              {!isFirstSlide && (
                <button
                  onClick={previousSlide}
                  className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700"
                >
                  Previous
                </button>
              )}
              <button
                onClick={onClose}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
              >
                Skip
              </button>
              {!isLastSlide ? (
                <button
                  onClick={nextSlide}
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                >
                  Next
                </button>
              ) : (
                <button
                  onClick={onClose}
                  className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
                >
                  Close
                </button>
              )}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default TutorialPopover;
