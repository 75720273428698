import React, { FC, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiUri } from "../../constants";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import {
  Mail,
  Lock,
  Phone,
  CheckCircle2,
  AlertCircle,
  Gift,
  ArrowRight,
  Scale
} from "lucide-react";

export interface PageSignUpProps {
  className?: string;
}

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [phone, setPhoneNumber] = useState("");
  const [unableToSignup, setUnableToSignup] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!isEmailValid) {
      return;
    }
    
    setIsLoading(true);
    try {
      const response = await axios.post(`${apiUri}/signup`, {
        email: email,
        password: password,
        phoneNumber: phone,
      });
      if (response.data.success) {
        window.location.href = "/login";
      }
    } catch (error) {
      console.error("Error posting form data:", error);
      setUnableToSignup(true);
    } finally {
      setIsLoading(false);
    }
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setIsEmailValid(validateEmail(emailValue));
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white py-20">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-md mx-auto"
        >
          {/* Header */}
          <div className="text-center mb-8">
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              className="w-16 h-16 mx-auto mb-4 rounded-full bg-gradient-to-r from-blue-600 to-purple-600 flex items-center justify-center"
            >
              <Scale className="w-8 h-8 text-white" />
            </motion.div>
            <h1 className="text-3xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
              Create Your Account
            </h1>
            <p className="text-gray-600 mt-2">
              Join our community of legal professionals
            </p>
          </div>

          {/* Trial Offer Banner */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="mb-8 p-4 rounded-xl bg-gradient-to-r from-purple-50 to-blue-50"
          >
            <div className="flex items-center space-x-3">
              <Gift className="w-6 h-6 text-purple-600" />
              <div>
                <h3 className="font-semibold text-gray-900">Free Trial Offer!</h3>
                <p className="text-sm text-gray-600">Get 10 free practice cases when you sign up</p>
              </div>
            </div>
          </motion.div>

          {/* Alert Messages */}
          <AnimatePresence>
            {unableToSignup && (
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="p-4 rounded-lg mb-6 flex items-center bg-red-50 text-red-600"
              >
                <AlertCircle className="w-5 h-5 mr-2" />
                <span>Email or mobile already exists.</span>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Sign Up Form */}
          <motion.form
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            onSubmit={handleSubmit}
            className="bg-white p-8 rounded-2xl shadow-lg space-y-6"
          >
            {/* Email Field */}
            <div>
              <label className="block text-gray-700 text-sm font-medium mb-2">
                Email Address
              </label>
              <div className="relative">
                <input
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  className={`
                    w-full px-4 py-3 rounded-lg border bg-gray-50 
                    focus:outline-none focus:ring-2 transition-all
                    ${isEmailValid 
                      ? 'border-green-300 focus:ring-green-200' 
                      : 'border-gray-200 focus:ring-blue-200'
                    }
                  `}
                  placeholder="you@example.com"
                />
                <Mail className={`
                  w-5 h-5 absolute right-3 top-1/2 transform -translate-y-1/2
                  ${isEmailValid ? 'text-green-500' : 'text-gray-400'}
                `} />
              </div>
            </div>

            {/* Password Field */}
            <div>
              <label className="block text-gray-700 text-sm font-medium mb-2">
                Password
              </label>
              <div className="relative">
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-4 py-3 rounded-lg border border-gray-200 bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-200 transition-all"
                  placeholder="Choose a strong password"
                />
                <Lock className="w-5 h-5 absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
            </div>

            {/* Phone Field */}
            <div>
              <label className="block text-gray-700 text-sm font-medium mb-2">
                Phone Number
              </label>
              <div className="relative">
                <PhoneInput
                  international
                  defaultCountry="IN"
                  value={phone}
                  onChange={(value) => setPhoneNumber(value || "")}
                  className="w-full px-4 py-3 rounded-lg border border-gray-200 bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-200 transition-all"
                />
                <Phone className="w-5 h-5 absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              disabled={isLoading || !isEmailValid}
              className={`
                w-full py-3 px-4 rounded-lg bg-gradient-to-r from-blue-600 to-purple-600 
                text-white font-medium flex items-center justify-center
                transform transition-all duration-300
                ${isLoading ? 'opacity-75 cursor-not-allowed' : 'hover:shadow-lg hover:-translate-y-1'}
                ${!isEmailValid ? 'opacity-60 cursor-not-allowed' : ''}
              `}
            >
              {isLoading ? (
                <motion.div
                  animate={{ rotate: 360 }}
                  transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                  className="w-5 h-5 border-2 border-white border-t-transparent rounded-full"
                />
              ) : (
                <>
                  Create Account
                  <ArrowRight className="w-5 h-5 ml-2" />
                </>
              )}
            </button>
          </motion.form>

          {/* Login Link */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
            className="text-center mt-6"
          >
            <p className="text-gray-600">
              Already have an account?{" "}
              <Link to="/login" className="text-blue-600 hover:text-blue-700 font-medium">
                Sign In
              </Link>
            </p>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default PageSignUp;
