import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { apiUri } from '../../constants';
import LoadingIcon from 'components/Loading/LoadingIcon';
import Alert from 'shared/Alert/Alert';
import { motion } from 'framer-motion';
import { Loader, School, AlertCircle, FileText, Clock, DollarSign, Users, Calendar, Trophy, Plus, Upload } from 'lucide-react';

interface UserRegisterProp {
  caseTitle: string;
  collegeName: string;
  time: string;
  cost: string;
  prizes: string[];
  specialReq: string;
  dateOfStart: string;
  dateOfEnd: string; 
  teamSize: string;
  description: string;
  casePdf: Blob;
}

const MootRegistrationForm: React.FC = () => {
  const [formData, setFormData] = useState<UserRegisterProp>({
    caseTitle: '',
    collegeName: '',
    time: '',
    cost: '',
    prizes: [''],
    specialReq: '',
    dateOfStart: '',
    dateOfEnd: '',
    teamSize: '',
    description: '',
    casePdf: new Blob(),
  });

    const [loading, setLoading] = useState(false)
  const [isErr, setIsErr] = useState(false)
  const [mootCourtId, setMootCourtId] = useState("")

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
    
    useEffect(() => {
    if (isErr) {
      const timer = setTimeout(() => setIsErr(false), 2000);
      return () => clearTimeout(timer); 
    }
  }, [isErr]);


  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      //console.log(file)
    if (file) {
      setFormData({ ...formData, casePdf: file });
    } 
  };

  const handlePrizesChange = (index: number, value: string) => {
    const updatedPrizes = formData.prizes.map((prize, i) =>
      i === index ? value : prize
    );
    setFormData({ ...formData, prizes: updatedPrizes });
  };

  const addPrizeField = () => {
    setFormData({ ...formData, prizes: [...formData.prizes, ''] });
  };

  const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      setLoading(true)
    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (key === 'prizes') {
        value.forEach((prize: string, index: number) => {
          formDataToSend.append(`prizes[${index}]`, prize);
        });
      } else {
        formDataToSend.append(key, value as string | Blob);
      }
    });

      try {
        //console.log(formDataToSend)
      const response = await axios.post(`${apiUri}/collegeMootReg`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
          
        setMootCourtId(response.data._id)
         
      } catch (error) {
         
        setLoading(false)
      }
    
    
    try {
  
        const r = await axios.post(`${apiUri}/initiateMootTeams`, { id: mootCourtId })
        
         setLoading(false)
          window.location.href = "/registerSuccess"
    }

    catch (error) {
      setLoading(false)
    }

    
  };

  return (
    <div className="max-w-4xl mx-auto p-8">
      {loading ? (
        <div className="flex items-center justify-center min-h-[400px]">
          <Loader className="w-8 h-8 text-purple-600 animate-spin" />
        </div>
      ) : (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white rounded-2xl shadow-xl overflow-hidden"
        >
          {/* Form Header */}
          <div className="bg-gradient-to-r from-purple-600 to-blue-600 p-6">
            <h2 className="text-2xl font-bold text-white flex items-center gap-2">
              <School className="w-6 h-6" />
              Register Your Moot Court Competition
            </h2>
            <p className="text-purple-100 mt-2">
              Fill in the details below to register your moot court competition
            </p>
          </div>

          {/* Error Alert */}
          {isErr && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              className="mx-6 mt-6 p-4 bg-red-50 text-red-600 rounded-lg flex items-center gap-2"
            >
              <AlertCircle className="w-5 h-5" />
              Error registering. Please try again later.
            </motion.div>
          )}

          <form onSubmit={handleSubmit} className="p-6 space-y-6">
            {/* Basic Details Section */}
            <div className="grid md:grid-cols-2 gap-6">
              <div className="space-y-6">
                {/* Case Title */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Case Title
                  </label>
                  <div className="mt-1 relative">
                    <input
                      type="text"
                      name="caseTitle"
                      value={formData.caseTitle}
                      onChange={handleChange}
                      className="w-full px-4 py-2.5 rounded-lg border focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                      required
                    />
                    <FileText className="w-5 h-5 text-gray-400 absolute right-3 top-1/2 transform -translate-y-1/2" />
                  </div>
                </div>

                {/* College Name */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    College Name
                  </label>
                  <div className="mt-1 relative">
                    <input
                      type="text"
                      name="collegeName"
                      value={formData.collegeName}
                      onChange={handleChange}
                      className="w-full px-4 py-2.5 rounded-lg border focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                      required
                    />
                    <School className="w-5 h-5 text-gray-400 absolute right-3 top-1/2 transform -translate-y-1/2" />
                  </div>
                </div>
              </div>

              <div className="space-y-6">
                {/* Time and Cost */}
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Duration
                    </label>
                    <div className="mt-1 relative">
                      <input
                        type="text"
                        name="time"
                        value={formData.time}
                        onChange={handleChange}
                        placeholder="e.g., 2 days"
                        className="w-full px-4 py-2.5 rounded-lg border focus:ring-2 focus:ring-purple-500 focus:border-transparent pl-10"
                        required
                      />
                      <Clock className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Registration Fee
                    </label>
                    <div className="mt-1 relative">
                      <input
                        type="text"
                        name="cost"
                        value={formData.cost}
                        onChange={handleChange}
                        placeholder="e.g., ₹1000"
                        className="w-full px-4 py-2.5 rounded-lg border focus:ring-2 focus:ring-purple-500 focus:border-transparent pl-10"
                        required
                      />
                      <DollarSign className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
                    </div>
                  </div>
                </div>

                {/* Team Size */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Team Size
                  </label>
                  <div className="mt-1 relative">
                    <input
                      type="text"
                      name="teamSize"
                      value={formData.teamSize}
                      onChange={handleChange}
                      placeholder="e.g., 2-3 members"
                      className="w-full px-4 py-2.5 rounded-lg border focus:ring-2 focus:ring-purple-500 focus:border-transparent pl-10"
                      required
                    />
                    <Users className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
                  </div>
                </div>
              </div>
            </div>

            {/* Dates Section */}
            <div className="grid md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Start Date
                </label>
                <div className="mt-1 relative">
                  <input
                    type="date"
                    name="dateOfStart"
                    value={formData.dateOfStart}
                    onChange={handleChange}
                    className="w-full px-4 py-2.5 rounded-lg border focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    required
                  />
                  <Calendar className="w-5 h-5 text-gray-400 absolute right-3 top-1/2 transform -translate-y-1/2" />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  End Date
                </label>
                <div className="mt-1 relative">
                  <input
                    type="date"
                    name="dateOfEnd"
                    value={formData.dateOfEnd}
                    onChange={handleChange}
                    className="w-full px-4 py-2.5 rounded-lg border focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    required
                  />
                  <Calendar className="w-5 h-5 text-gray-400 absolute right-3 top-1/2 transform -translate-y-1/2" />
                </div>
              </div>
            </div>

            {/* Prizes Section */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Prizes
              </label>
              <div className="space-y-3">
                {formData.prizes.map((prize, index) => (
                  <div key={index} className="relative">
                    <Trophy className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
                    <input
                      type="text"
                      value={prize}
                      onChange={(e) =>
                        handlePrizesChange(index, e.target.value)
                      }
                      placeholder={`${index + 1}${
                        index === 0
                          ? "st"
                          : index === 1
                          ? "nd"
                          : index === 2
                          ? "rd"
                          : "th"
                      } Prize`}
                      className="w-full px-4 py-2.5 rounded-lg border focus:ring-2 focus:ring-purple-500 focus:border-transparent pl-10"
                      required
                    />
                  </div>
                ))}
                <button
                  type="button"
                  onClick={addPrizeField}
                  className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-purple-600 hover:text-purple-700"
                >
                  <Plus className="w-4 h-4" />
                  Add Another Prize
                </button>
              </div>
            </div>

            {/* Description and Requirements */}
            <div className="grid md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Description
                </label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  rows={4}
                  className="mt-1 w-full px-4 py-2.5 rounded-lg border focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Special Requirements
                </label>
                <textarea
                  name="specialReq"
                  value={formData.specialReq}
                  onChange={handleChange}
                  rows={4}
                  className="mt-1 w-full px-4 py-2.5 rounded-lg border focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                />
              </div>
            </div>

            {/* PDF Upload */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Case PDF
              </label>
              <div className="mt-1">
                <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-lg hover:border-purple-500 transition-colors">
                  <div className="space-y-1 text-center">
                    <Upload className="mx-auto h-12 w-12 text-gray-400" />
                    <div className="flex text-sm text-gray-600">
                      <label
                        htmlFor="casePdf"
                        className="relative cursor-pointer rounded-md font-medium text-purple-600 hover:text-purple-700"
                      >
                        <span>Upload a file</span>
                        <input
                          id="casePdf"
                          name="casePdf"
                          type="file"
                          accept="application/pdf"
                          onChange={handleFileChange}
                          className="sr-only"
                          required
                        />
                      </label>
                      <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs text-gray-500">PDF up to 10MB</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Submit Button */}
            <div className="flex justify-end pt-6">
              <button
                type="submit"
                className="inline-flex items-center px-6 py-3 rounded-lg bg-gradient-to-r from-purple-600 to-blue-600 text-white font-medium hover:from-purple-700 hover:to-blue-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transform transition-transform hover:-translate-y-0.5"
              >
                Register Competition
              </button>
            </div>
          </form>
        </motion.div>
      )}
    </div>
  );
};

export default MootRegistrationForm;
