import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { motion, AnimatePresence } from "framer-motion";
import SectionLatestPosts from "./SectionLatestPosts";
import Alert from "shared/Alert/Alert";
import {
  Sparkles,
  Bell,
  BookOpen,
  ChevronDown,
  Star,
  Coffee,
} from "lucide-react";
import axios from "axios";
import { apiUri } from "../../constants";
import NewsletterConfirmation from "./NewsletterPopoverSuccess";

const BlogPage: React.FC = () => {
  const [showNewsletter, setShowNewsletter] = useState(true);
  const [email, setEmail] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("Something went wrong");
  const [showPopover, setShowPopover] = useState(false);

  const scrollToSubscribe = () => {
    document
      .getElementById("subscribe")
      ?.scrollIntoView({ behavior: "smooth" });
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handlePopoverClose = () => {
    setShowPopover(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  const handleJoinNewsletter = async () => {
    if (isValidEmail(email)) {
      try {
        const response = await axios.post(`${apiUri}/addToNewsletter`, {
          email: email,
        });

        if (response.data.success) {
          setShowPopover(true);
        } else {
          console.log(response.data.message || "Failed to subscribe.");
        }
      } catch (error) {
        setAlertMsg(
          "Unable to subscribe to the newsletter. Kindly try again later."
        );
        setShowAlert(true);
      }
    } else {
      setShowAlert(true);
      setAlertMsg("Email not valid.");
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-pink-50 to-purple-50 dark:from-gray-900 dark:to-purple-900 overflow-hidden relative">
      <Helmet>
        <title>Verdict Vibes | Where Legal Learning Meets Fun! 🎉</title>
        <meta
          name="description"
          content="Join the coolest legal community! Discover fun takes on law, memes that'll make your day, and actually enjoyable legal learning. Verdict Vibes - where legal knowledge meets good vibes! ✨"
        />
      </Helmet>

      {/* Fun Newsletter Banner */}
      {/* <AnimatePresence>
        {showNewsletter && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            className="bg-gradient-to-r from-violet-500 to-fuchsia-500 text-white"
          >
            <div className="container mx-auto px-4 py-3 flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Sparkles className="w-5 h-5" />
                <span className="text-sm">
                  Join our vibe tribe for weekly legal fun! ✨
                </span>
              </div>
              <div className="flex items-center gap-4">
                <button
                  className="px-4 py-1 bg-white text-fuchsia-500 rounded-full text-sm hover:bg-opacity-90 transition-colors font-medium"
                  onClick={scrol}
                >
                  Vibe with us!
                </button>
                <button
                  onClick={() => setShowNewsletter(false)}
                  className="text-white/80 hover:text-white"
                >
                  ✕
                </button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence> */}

      {showPopover && (
        <NewsletterConfirmation
          isOpen={showPopover}
          email={email}
          onClose={handlePopoverClose}
        />
      )}
      {/* Hero Section */}
      <div className="relative bg-gradient-to-r from-violet-600 via-fuchsia-500 to-pink-500 animate-gradient-party text-white py-24 overflow-hidden">
        {/* Animated Background */}

        <div className="absolute inset-0 opacity-10">
          <svg
            className="w-full h-full"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <pattern
              id="grid"
              width="10"
              height="10"
              patternUnits="userSpaceOnUse"
            >
              <path
                d="M 10 0 L 0 0 0 10"
                fill="none"
                stroke="white"
                strokeWidth="0.5"
              />
            </pattern>
            <rect width="100" height="100" fill="url(#grid)" />
          </svg>
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="container mx-auto px-4 relative z-10"
        >
          <div className="max-w-3xl">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 }}
            >
              <h1 className="text-6xl font-bold mb-6 leading-tight bg-clip-text text-transparent bg-gradient-to-r from-white to-pink-100">
                Verdict Vibes ⚖️✨
              </h1>
              <p className="text-2xl text-pink-100 mb-8">
                Where legal learning gets a glow-up! Join us for a weekly fresh
                takes, epic memes, and actually fun legal content. No boring
                stuff allowed!
              </p>
            </motion.div>

            <div className="flex flex-wrap gap-4">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={scrollToSubscribe}
                className="px-6 py-3 bg-white text-fuchsia-600 rounded-xl font-medium flex items-center gap-2 shadow-lg"
              >
                <Star className="w-5 h-5" />
                Get the Vibes
              </motion.button>
            </div>
          </div>
        </motion.div>

        <motion.div
          animate={{ y: [0, -10, 0] }}
          transition={{ duration: 2, repeat: Infinity }}
          className="absolute bottom-4 left-1/2 transform -translate-x-1/2"
        >
          <ChevronDown className="w-8 h-8 text-white/70" />
        </motion.div>
      </div>

      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {[
            {
              title: "Tech Updates",
              icon: "💻",
              desc: "Major advancements in the legal tech field, explained simply.",
            },
            {
              title: "Public Pulse",
              icon: "🗣️",
              desc: "Insights into public opinion on law and current legal judgments.",
            },
            {
              title: "Raw and Real",
              icon: "📰",
              desc: "A law-focused newspaper free from government crackdowns.",
            },
          ].map((category) => (
            <motion.button
              key={category.title}
              whileHover={{ scale: 1.03, y: -5 }}
              className="p-6 bg-white dark:bg-gray-800 rounded-2xl shadow-lg hover:shadow-xl transition-all border border-pink-100 dark:border-purple-800"
            >
              <div className="text-3xl mb-3">{category.icon}</div>
              <h3 className="text-xl font-bold text-fuchsia-600 dark:text-fuchsia-400 mb-2">
                {category.title}
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                {category.desc}
              </p>
            </motion.button>
          ))}
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-4 py-12">
        <SectionLatestPosts />
      </div>

      {/* Join the Vibe Section */}
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        className="bg-gradient-to-r from-violet-600 to-fuchsia-600 text-white py-20"
      >
        <div className="container mx-auto px-4" id="subscribe">
          {showAlert && <Alert type="error" children={alertMsg} />}
          <div className="max-w-2xl mx-auto text-center animate-gradient-shimmer">
            <Sparkles className="w-16 h-16 mx-auto mb-6 text-pink-300" />
            <h2 className="text-4xl font-bold mb-4">Join the Vibe Tribe! ✨</h2>
            <p className="text-pink-100 text-lg mb-8">
              Get the freshest legal content, memes that'll make your day, and
              actually useful insights. No boring newsletters, we promise!
            </p>
            <div className="flex gap-4 max-w-md mx-auto">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Drop your email here! ✉️"
                aria-label="Email address"
                required
                className="flex-1 px-6 py-3 bg-white/10 rounded-xl border border-white/20 text-white placeholder:text-pink-200 focus:outline-none focus:border-pink-300"
              />

              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleJoinNewsletter}
                className="px-6 py-3 bg-white text-fuchsia-600 rounded-xl font-medium hover:bg-pink-100 transition-colors shadow-lg"
              >
                Let's Vibe!
              </motion.button>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default BlogPage;
