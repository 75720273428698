import React, { FC, useRef, useEffect, useState } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Button from "shared/Button/Button";
import { init, animateCamera, toggleCharacterVisibility } from "./threeUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faRobot,
  faMicrophone,
  faBrain,
  faNotesMedical,
  faArrowLeft,
  faGavel,
  faKeyboard,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import NotesPopover from "components/CaseTools/NotesPopover";
import axios from "axios";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import IpcBotPopover from "components/CaseTools/IPCbot/IpcBotPopover";
import MessageBox from "./messageBox";
import { Message } from "./types";
import CaseDetailsPopover from "components/CaseTools/CaseDetailsPopover";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { apiUri, modelUri } from "../../constants";
import { Alert } from "shared/Alert/Alert";
import TutorialPopover from "containers/Tutorial/Tutorial";
import LoadingButton from "components/Loading/LoadingButton";
import Sidebar from "components/Sidebar/Sidebar";
import CaseCompletedPopover from "./caseCompletedPopover";
import { motion, AnimatePresence } from "framer-motion";
import MessageDisplay from "./messageBox";
import ConversationPopover from "components/Sidebar/Sidebar";
import ExitConfirmation from "./ExitPopover";
import PreventNavigation from "./PreventNavigation";
import ThinkingPopover from "./LawyerThinkingPopover";
import StreamAnalyzer from "./VideoSteamAnalyzer";

const PageCourt: FC<{}> = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const sceneRef = useRef<THREE.Scene>();
  const cameraRef = useRef<THREE.PerspectiveCamera>();
  const rendererRef = useRef<THREE.WebGLRenderer>();
  const controlsRef = useRef<OrbitControls>();
  const textSpriteRef = useRef<THREE.Sprite>();
  const textSpriteRefLawyer2 = useRef<THREE.Sprite>();
  const witnessCharacterRef = useRef<THREE.Object3D | null>(null);
  const statsRef = useRef<Stats>();
  const [lawyer2Text, setLawyer2Text] = useState<string>("");
  const conversationLogRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [fullUserConversation, setFullUserConversation] = useState<string>("");
  const [isTutorialOpen, setisTutorialOpen] = useState(true);

  const [isNotesPopoverOpen, setIsNotesPopoverOpen] = useState(false);
  const [isCaseDetailsPopoverOpen, setisCaseDetailsPopoverOpen] =
    useState(false);
  const [isBotPopoverOpen, setIsBotPopoverOpen] = useState(false);
  const [input, setInput] = useState<string>("");
  const [isListening, setIsListening] = useState<boolean>(false);
  const { transcript, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();
  const [witnessInStand, setWitnessInStand] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const [analysisReady, setAnalysisReady] = useState(false);

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const [sideMenuIsExpand, setSideMenuIsExpand] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const [isCaseComplete, setIsCaseComplete] = useState(false);
  const CASEID = sessionStorage.getItem("caseId") || "";
  const [numCaseCompleted, setNumCaseCompleted] = useState(0);
  const [messageCount, setMessageCount] = useState(sessionStorage.getItem("messages")?.length || 0);
  const [isOpen, setIsOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [showExit, setShowExit] = useState(false);

  useEffect(() => {
    init(
      sceneRef,
      cameraRef,
      canvasRef,
      rendererRef,
      controlsRef,
      textSpriteRef,
      textSpriteRefLawyer2,
      statsRef,
      witnessCharacterRef
    );
  }, []);

  useEffect(() => {
    if (conversationLogRef.current) {
      conversationLogRef.current.scrollTop =
        conversationLogRef.current.scrollHeight;
    }
  }, [lawyer2Text]);

  useEffect(() => {
    //console.log("VALUE OF WITNESS IS:" + witnessInStand)
    toggleCharacterVisibility(
      witnessInStand,
      witnessCharacterRef,
      sceneRef,
      cameraRef,
      rendererRef
    );
  }, [witnessInStand]);

  // useEffect(() => {
  //   updateTextSprite(dynamicText, sceneRef, textSpriteRef, { x: -3.55, y: 1.5, z: 2 });
  // }, [dynamicText]);

  // useEffect(() => {
  //   updateTextSprite(lawyer2Text, sceneRef, textSpriteRefLawyer2, { x: -3, y: 1.5, z: -1.5 });
  // }, [lawyer2Text]);

  const caseActive: string = sessionStorage.getItem("caseId") || "";

  let iPrompt: string = "";
  let iWitnessPrompt: string = "";

  const getPrompts = async () => {
    try {
      const response = await axios.post(`${apiUri}/getPrompts`, {
        caseId: caseActive,
      }); // Make sure to include this option to send cookies with requests
      //console.log(response.data.data);
      iPrompt = response.data.data.initialPrompt;
      iWitnessPrompt = response.data.data.witnessPrompt;
    } catch (error) {
      console.error("Error setting prompts:", error);
    }
  };

  const getStoredMessages = (): Message[] => {
    const storedMessages = sessionStorage.getItem("messages");
    return storedMessages ? JSON.parse(storedMessages) : [];
  };

  useEffect(() => {
    setMessages(getStoredMessages());
  }, []);

  useEffect(() => {
    if (transcript && !isListening) {
      setInput(transcript);
      // setFullUserConversation((prevConversation) => prevConversation + ' ' + transcript);
      //console.log(fullUserConversation);
      resetTranscript();
    }
  }, [transcript, isListening, fullUserConversation, resetTranscript]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (messages.length > 0) {
      if (messages[messages.length - 1].role === "judge") {
        animateCamera(
          cameraRef,
          controlsRef,
          new THREE.Vector3(-2, 1, -0.3),
          new THREE.Vector3(-4, 1, -0.3),
          3000
        );
      } else if (messages[messages.length - 1].role === "lawyer") {
        animateCamera(
          cameraRef,
          controlsRef,
          new THREE.Vector3(-1, 2, -3),
          new THREE.Vector3(-3.55, 0, -1),
          3000
        );
      } else {
        animateCamera(
          cameraRef,
          controlsRef,
          new THREE.Vector3(-1, 2, 3),
          new THREE.Vector3(-3.55, 0, 1),
          3000
        );
      }
    }
  }, [messages]);

  useEffect(() => {
    sessionStorage.setItem("messages", JSON.stringify(messages));
  }, [messages]);

  const startVoiceRecognition = () => {
    if (browserSupportsSpeechRecognition) {
      SpeechRecognition.startListening({ continuous: true });
      setIsListening(true);
    } else {
      console.error("Browser does not support speech recognition.");
    }
  };

  const handleVoiceInput = () => {
    startVoiceRecognition();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
    resetTranscript();
  };

  const handleMicEnd = () => {
    SpeechRecognition.stopListening();
    setIsListening(false);
  };

  useEffect(() => {
    sessionStorage.setItem("fullUserConversation", fullUserConversation);
  }, [fullUserConversation]);

  useEffect(() => {
    if (input === "i rest my case") {
      handleRestMyCase();
    }
  }, [input]);

  const handleSendMessage = async () => {
    setIsProcessing(true);
    setMessageCount(messageCount + 1);
    await getPrompts();
    if (input.trim() !== "") {
      const newMessage: Message = { role: "User", text: input };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setFullUserConversation(
        (prevConversation) => `${prevConversation} ${input}`
      );
      setInput("");
      resetTranscript();

      try {
        const response = await axios.post(`${modelUri}/model`, {
          message: input,
          initial_prompt: iPrompt,
          witness_prompt: iWitnessPrompt,
          userSide: sessionStorage.getItem("userSide"),
        });
        const role = response.data.speaker;
        const botMessage: Message = { role, text: response.data.msg };

        setMessages((prevMessages) => [...prevMessages, botMessage]);
        setLawyer2Text(response.data.msg);
        setIsOpen(true);
      } catch (error) {
        console.error("There was an error making the POST request!", error);
      }
      setInput("");
      resetTranscript();
      setIsProcessing(false);
    }
  };

  const handleButtonClick = () => {
    setIsNotesPopoverOpen(true);
  };

  const handleClosePopover = () => {
    setIsNotesPopoverOpen(false);
  };

  const handleBotClosePopover = () => {
    setIsBotPopoverOpen(false);
  };

  const handleBotClick = () => {
    setIsBotPopoverOpen(true);
  };

  const handleTutorialPopover = () => {
    setisTutorialOpen(false);
  };

  const handleDetailClick = () => {
    setisCaseDetailsPopoverOpen(true);
  };

  const handleCLoseDetailsPopover = () => {
    setisCaseDetailsPopoverOpen(false);
  };

  const handleQuestionWitness = () => {
    setInput("i want to question the witness");
    handleSendMessage();
  };

  const handleRestMyCase = async () => {
    await handleSendMessage();
    setAnalysisReady(true);
  };

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => setShowAlert(false), 4000);
      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  const handleAnalytics = async () => {
    if (messageCount < 5) {
      setAlertMsg("Conversation too short, kindly continue the argument.");
      setShowAlert(true);
      return;
    } else {
      try {
        const a = await axios.post(`${apiUri}/addToCompletedCase`, {
          caseId: sessionStorage.getItem("caseId"),
          email: sessionStorage.getItem("email"),
        });
        setIsCaseComplete(true);
        sessionStorage.removeItem("messages");
        // console.log(a.data.data);
      } catch (error) {
        // console.log(error);
      }

      setTimeout(function () {
        window.location.href = "/analysis";
      }, 5000);
    }
  };

  const handleExitCourt = () => {
    setShowExit(true);
  };

  const handleExitPopoverClose = () => {
    setShowExit(false);
  };

  return (
    <div className="relative min-h-screen bg-gradient-to-br from-purple-50 to-blue-50">
      {/* <PreventNavigation /> */}

      <ConversationPopover
        isExpand={isExpand}
        setIsExpand={setIsExpand}
        setExpand={setSideMenuIsExpand}
        messages={messages}
        conversationLogRef={conversationLogRef}
      />

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className={`flex-1 min-h-screen transition-all duration-300 ease-in-out
          `}
      >
        {/* Popup Handlers */}
        <AnimatePresence>
          {isCaseComplete && (
            <CaseCompletedPopover isOpen={true} caseTitle={CASEID} />
          )}
          {isTutorialOpen && (
            <TutorialPopover onClose={handleTutorialPopover} />
          )}
        </AnimatePresence>

        <div className="flex flex-col lg:flex-row  p-4 gap-4">
          {/* Main Court Area */}
          <div className="flex flex-col lg:w-3/4 h-full">
            {/* Opponent Message Box */}
            <motion.div
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className="mb-4"
            >
              {showAlert && <Alert children={alertMsg} type="error" />}

              {lawyer2Text && isOpen && (
                <MessageDisplay
                  text={lawyer2Text}
                  role={
                    messages[messages.length - 1].role === "judge"
                      ? "judge"
                      : "prosecutor"
                  }
                  onClose={() => setIsOpen(false)}
                />
              )}
            </motion.div>

            {/* Court Visual */}
            <div className="flex-grow relative">
              <canvas
                ref={canvasRef}
                className="w-full h-full rounded-2xl shadow-lg"
              />
              <div className="absolute bottom-4 right-4 bg-white/80 backdrop-blur-sm rounded-lg p-2 text-sm text-gray-600">
                📍 Virtual Courtroom
              </div>
            </div>
          </div>

          {/* Control Panel */}

          <motion.div
            initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            className="space-y-3"
          >
            {/* Input Section */}
            <div className="bg-white rounded-xl shadow-lg p-4 border-2 border-purple-100">
              <div className="flex items-center justify-between ">
                {/* <h3 className="font-semibold text-gray-700">Your Response</h3> */}
                <div className="flex items-center gap-2"></div>
              </div>

              <div className="w-full aspect-video rounded-lg overflow-hidden bg-gray-100 mb-2">
                <StreamAnalyzer serverUrl={modelUri} />
              </div>

              <textarea
                className="w-full h-24 p-3 rounded-lg border-2 border-gray-200 focus:border-purple-400 focus:ring focus:ring-purple-100 transition-all text-sm"
                placeholder="Enter your arguments here..."
                value={input}
                onChange={handleInputChange}
              />

              <div className="flex gap-2 mt-2">
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="flex-1 flex items-center justify-center gap-2 p-2 rounded-lg bg-gradient-to-r from-purple-600 to-blue-600 text-white text-sm font-medium"
                  onClick={handleSendMessage}
                >
                  Send Message
                </motion.button>

                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className={`w-12 flex items-center justify-center rounded-lg 
          ${
            isListening ? "bg-red-500 text-white" : "bg-gray-100 text-gray-700"
          }`}
                  onClick={isListening ? handleMicEnd : handleVoiceInput}
                >
                  <FontAwesomeIcon icon={faMicrophone} />
                </motion.button>
              </div>
            </div>

            {/* Legal Tools Section */}
            <div className="bg-white rounded-xl shadow-lg p-4 border-2 border-purple-100">
              <h3 className="font-semibold text-gray-700 mb-2">Legal Tools</h3>
              <div className="grid grid-cols-2 gap-2">
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={handleDetailClick}
                  className="flex items-center gap-2 p-2 rounded-lg bg-gray-50 hover:bg-gray-100 text-gray-700 text-sm"
                >
                  <FontAwesomeIcon icon={faBook} className="text-purple-500" />
                  Case Details
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={handleBotClick}
                  className="flex items-center gap-2 p-2 rounded-lg bg-gray-50 hover:bg-gray-100 text-gray-700 text-sm"
                >
                  <FontAwesomeIcon icon={faRobot} className="text-purple-500" />
                  Avyukta Bot
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => setIsExpand(!isExpand)}
                  className="flex items-center gap-2 p-2 rounded-lg bg-gray-50 hover:bg-gray-100 text-gray-700 text-sm"
                >
                  <FontAwesomeIcon icon={faBrain} className="text-purple-500" />
                  View Logs
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={handleButtonClick}
                  className="flex items-center gap-2 p-2 rounded-lg bg-gray-50 hover:bg-gray-100 text-gray-700 text-sm"
                >
                  <FontAwesomeIcon
                    icon={faNotesMedical}
                    className="text-purple-500"
                  />
                  My Notes
                </motion.button>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="grid grid-cols-2 gap-2">
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={handleAnalytics}
                className="p-2.5 rounded-xl bg-gradient-to-r from-red-500 to-orange-500 text-white text-sm font-medium shadow-lg"
              >
                <FontAwesomeIcon icon={faGavel} className="mr-2" />
                Request Judgment
              </motion.button>

              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={handleExitCourt}
                className="p-2.5 rounded-xl bg-purple-500 text-white text-sm font-medium"
              >
                <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                Exit Court
              </motion.button>
            </div>
          </motion.div>
        </div>
      </motion.div>

      {/* Popovers */}
      <AnimatePresence>
        {isCaseDetailsPopoverOpen && (
          <CaseDetailsPopover onClose={handleCLoseDetailsPopover} />
        )}
        {isProcessing && (
          <ThinkingPopover
            isVisible={isProcessing}
            gifSrc="lawyerThinking.gif"
          />
        )}
        {showExit && <ExitConfirmation onClose={handleExitPopoverClose} />}
        {isBotPopoverOpen && <IpcBotPopover onClose={handleBotClosePopover} />}
        {isNotesPopoverOpen && <NotesPopover onClose={handleClosePopover} />}
      </AnimatePresence>
    </div>
  );
};

export default PageCourt;
