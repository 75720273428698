import React, { FC, useEffect, useState } from "react";
import Badge from "shared/Badge/Badge";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DetailPagetLayout from "containers/ListingDetailPage/Layout";
import PdfViewer from "./PdfViewer";
import axios from "axios";
import { apiUri } from "../../constants";
import LoadingIcon from "components/Loading/LoadingIcon";
import TeamRegisterPopover from "./TeamRegisterPopover";
import UserMootProgress from "./UserMootProgress";

interface UserRegisterProp {
    caseTitle: string;
    collegeName: string;
    time: string;
    cost: string;
    prizes: string[];
    specialReq: string;
    dateOfStart: string;
    dateOfEnd: string; 
    teamSize: string;
  description: string;
  casePdf: Blob
}

const UserRegistration: FC<UserRegisterProp> = ({ caseTitle, collegeName, time,  prizes, dateOfStart, dateOfEnd, specialReq, teamSize, cost, description, casePdf }) => {

  const [regPopOpen, setIsRegPopOpen] = useState(false)
  const [isUserReg, setIsReg] = useState(false)

   const [isAfterStart, setIsAfterStart] = useState(false);

  useEffect(() => {
    const startDate = new Date(dateOfStart); 
    const currentDate = new Date();

    if (currentDate > startDate) {
      setIsAfterStart(true);
    }
  }, [dateOfStart]);

  const handlePopClose = () => {

  
    setIsRegPopOpen(false)
  }


  const handleScheduleClick = () => {
    
    window.location.href = '/fullSchedule'

  }

   useEffect(() => {
    const fetchIsUserReg = async () => {
      try {
        const response = await axios.post(`${apiUri}/fetchIsUserReg`, {
          mootCourtId: sessionStorage.getItem("FetchMootCourtId"),
          member: sessionStorage.getItem("email"),
        });

        if (response.data.success) {
          setIsReg(true);
        } else {
          setIsReg(false);
        }
      } catch (error) {
        console.error('Error checking user registration:', error);
        setIsReg(false);
      }
    };

    fetchIsUserReg();
   }, []);
  
  const renderSection1 = () => {
  
 
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <Badge color="pink" name="Register" />
          {/* <LikeSaveBtns /> */}
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {caseTitle}
        </h2>

    
        <div className="flex items-center">
       
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Hosted by{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              {collegeName}
            </span>
          </span>
        </div>

        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

     
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-clock text-2xl"></i>
            <span className="">{time}</span>
          </div>
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-user-friends text-2xl"></i>
                    <span className="">{teamSize}</span>
          </div>
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-language text-2xl"></i>
            <span className="">English</span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Description</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
                <p>
                    {description}
          </p>
            </div>
            
            <PdfViewer pdfBlob={casePdf} />
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Things to know</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

       
        <div>
          <h4 className="text-lg font-semibold">Prizes</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            <ul className="list-disc list-inside mb-4">
                {prizes.map((prize, index) => (
                    <li key={index} className="text-lg">
                        {prize}
                    </li>
                ))}
            </ul>
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

    
        <div>
          <h4 className="text-lg font-semibold">Requirements</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            {specialReq}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">What to bring</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              <li>
                Formal Wear
              </li>
                        <li>Join and be ready on the given slot time</li>
                         <li>Late joining may lead to disqualification</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const renderSidebar = () => {

    const handleRegister = () => {
        setIsRegPopOpen(true)
    }
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            {cost}
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /Team
            </span>
          </span>
          {/* <StartRating /> */}
        </div>

     

        {/* SUM */}
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Date of start</span>
                    <span>{dateOfStart}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Date of end</span>
                    <span>{dateOfEnd}</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          {/* <div className="flex justify-between font-semibold">
            <span>Prizes</span>
                    <span>{prizes}</span>
          </div> */}
        </div>

        {isUserReg ? (
        isAfterStart ? (
            <button className="relative text-sm sm:text-base font-medium px-4 py-3 sm:px-6 rounded-full transition-colors text-white bg-green-600 hover:bg-green-800" onClick={handleScheduleClick}>
            Go to Schedule
          </button>
        ) : (
          <button className="relative text-sm sm:text-base font-medium px-4 py-3 sm:px-6 rounded-full transition-colors text-white bg-fuchsia-600 hover:bg-fuchsia-800">
            Already registered
          </button>
        )
      ) : (
        <ButtonPrimary onClick={handleRegister}>Register</ButtonPrimary>
      )}
        
        {/* <UserMootProgress numberOfSteps={3} stepStatuses={['success', 'active', 'pending']}/> */}
      </div>
    );
  };

  return (
    <div className={` nc-UserRegisterMoot `}>
     

      {/* MAIn */}
      <main className="relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:pr-10 lg:space-y-10">
             { regPopOpen && <TeamRegisterPopover onClose={handlePopClose} teamSize={Number(teamSize)} /> }
    
          {renderSection1()}
          {renderSection2()}   
     
          {renderSection8()}
        </div>

        {/* SIDEBAR */}
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28">{renderSidebar()}</div>
        </div>
      </main>
    </div>
  );
};

export default function UserRegisterMoot() {
    
  const [data, setData] = useState<UserRegisterProp | null>(null)
  const [loading, setIsLoading] = useState(true)
  
  
  useEffect(() => {

    const fetchDetails = async () => {
      try {
        
        const response = await axios.post(`${apiUri}/fetchRegMoot`, {
          id: sessionStorage.getItem("FetchMootCourtId"),
        });
    
        const res = await axios.post(`${apiUri}/fetchMootPdf`, {
          id: sessionStorage.getItem("FetchMootCourtId"),
        }, { responseType: 'blob' });
        
        response.data.data.casePdf = res.data 

        setData(response.data.data)

      } catch (error) {
        console.error('Error fetching case details:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDetails();
  }, []);

  if (loading) {
    return <LoadingIcon />;
  } else if (data) {
    //console.log(data);
 
    return (
      <DetailPagetLayout>
        <UserRegistration  {...data} />
      </DetailPagetLayout>
      
    )
  }
}
