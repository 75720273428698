import React, { useEffect, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { apiUri } from '../../constants';
import axios from 'axios';
import Alert from 'shared/Alert/Alert';

interface TeamRegisterPopoverProps {
  onClose: () => void;
  teamSize: number;
}

const TeamRegisterPopover: React.FC<TeamRegisterPopoverProps> = ({ onClose, teamSize }) => {
  const [teamName, setTeamName] = useState('');
  const [captain, setCaptain] = useState('');
  const [members, setMembers] = useState<string[]>(new Array(teamSize).fill(''));
    const [errors, setErrors] = useState<{ teamName: string; members: string[]; captain: string }>({ teamName: '', members: new Array(teamSize).fill(''), captain: ''});
  const [isMemberReg, setIsMemberReg] = useState<boolean[]>(new Array(teamSize).fill(false));
  const [allGood, setAllGood] = useState(false);
  const [isErr, setIsErr] = useState(false);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const checkTeamNameUniqueness = async (name: string) => {
    try {
      const response = await axios.post(`${apiUri}/isTeamNameUniq`, { mootCourtId: sessionStorage.getItem("FetchMootCourtId"), teamName: name });
      if (!response.data.success) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          teamName: 'Team name is already taken',
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          teamName: '',
        }));
      }
    } catch (error) {
      //console.log(error)
    }
  };

  const handleTeamNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTeamName(value);
    checkTeamNameUniqueness(value);
  };

  const handleMemberChange = async (index: number, value: string) => {
    const newMembers = [...members];
    newMembers[index] = value;
    setMembers(newMembers);

    if (!emailRegex.test(value)) {
      const newErrors = { ...errors };
      newErrors.members[index] = 'Invalid email address';
      setErrors(newErrors);
      return;
    } else if (newMembers.filter(email => email === value).length > 1) {
      const newErrors = { ...errors };
      newErrors.members[index] = 'Duplicate email address within the team';
      setErrors(newErrors);
      return;
    } else {
      const newErrors = { ...errors };
      newErrors.members[index] = '';
      setErrors(newErrors);
    }

    try {
      const isUserInPlatform = await axios.post(`${apiUri}/userExist`, { email: value });

      if (isUserInPlatform.data.success) {
        const fetchRegResponse = await axios.post(`${apiUri}/fetchIsUserReg`, {
          mootCourtId: sessionStorage.getItem("FetchMootCourtId"),
          member: value,
        });

        const newIsMemberReg = [...isMemberReg];
        newIsMemberReg[index] = fetchRegResponse.data.success;
        setIsMemberReg(newIsMemberReg);

        if (fetchRegResponse.data.success) {
          const newErrors = { ...errors };
          newErrors.members[index] = 'User is already registered for this moot court';
          setErrors(newErrors);
        }
      } else {
        const newIsMemberReg = [...isMemberReg];
        newIsMemberReg[index] = false;
        setIsMemberReg(newIsMemberReg);

        const newErrors = { ...errors };
        newErrors.members[index] = 'User is not registered on the platform. Kindly Sign Up.';
        setErrors(newErrors);
      }
    } catch (error) {
      console.error('Error checking user registration:', error);
      const newErrors = { ...errors };
      newErrors.members[index] = 'Error checking registration status';
      setErrors(newErrors);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!members.includes(captain)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        captain: 'The captain must be one of the team members',
      }));
      return;
    }

    try {
      const result = await axios.post(`${apiUri}/addUserToMoot`, {
        teamName,
        captain,
        members,
        mootCourtId: sessionStorage.getItem("FetchMootCourtId"),
      });

      if (result.data.success) {

        try {

          const resp = await axios.post(`${apiUri}/addToIdle,`, { idealTeams: [teamName] })
               
          if (resp.data.success) {
            setAllGood(true);

            setTimeout(() => {
              onClose();
            }, 8000);
          }
              
     
        }
        catch (error) {
          setIsErr(true)
        }
      }
        
    } catch (error) {
      setIsErr(true);
    }
  };

  useEffect(() => {
    if (isErr) {
      const timer = setTimeout(() => setIsErr(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [isErr]);

  return (
    <Popover>
      <Transition
        show={true}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Popover.Panel static className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
            {isErr && <Alert children="Unable to register. Please try again later." type="error" />}
            {allGood && <Alert children="Registered successfully" type="success" />}

            <h2 className="text-2xl font-bold mb-4">Register Your Team</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2" htmlFor="teamName">
                  Team Name
                </label>
                <input
                  id="teamName"
                  type="text"
                  value={teamName}
                  onChange={(e) => handleTeamNameChange(e)}
                  className={`w-full px-3 py-2 border ${errors.teamName ? 'border-red-500' : 'border-gray-300'} rounded-md`}
                  required
                />
                {errors.teamName && <p className="text-red-500 text-sm mt-1">{errors.teamName}</p>}
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 mb-2" htmlFor="captain">
                  Captain
                              </label>
                              <p>Must be one of the members</p>
                <input
                  id="captain"
                  type="text"
                  value={captain}
                  onChange={(e) => setCaptain(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  required
                />
                {errors.captain && <p className="text-red-500 text-sm mt-1">{errors.captain}</p>}
              </div>

              {members.map((member, index) => (
                <div key={index} className="mb-4">
                  <label className="block text-gray-700 mb-2" htmlFor={`member${index}`}>
                    Team Member {index + 1} (Email)
                  </label>
                  <input
                    id={`member${index}`}
                    type="email"
                    value={member}
                    onChange={(e) => handleMemberChange(index, e.target.value)}
                    className={`w-full px-3 py-2 border ${errors.members[index] ? 'border-red-500' : 'border-gray-300'} rounded-md`}
                    required
                  />
                  {errors.members[index] && <p className="text-red-500 text-sm mt-1">{errors.members[index]}</p>}
                  {!errors.members[index] && !isMemberReg[index] && (
                    <p className="text-green-500 text-sm mt-1">User is eligible.</p>
                  )}
                </div>
              ))}

              <div className="my-4">
                <h3 className="text-lg font-semibold mb-2">Important Instructions</h3>
                <ul className="list-disc list-inside text-gray-600">
                  <li>Arrive 5-10 minutes before the scheduled time.</li>
                  <li>Wear formal attire suitable for a legal setting.</li>
                  <li>Avoid using inappropriate or foul language.</li>
                </ul>
              </div>

              <div className="flex justify-end mt-6">
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                >
                  Register Team
                </button>
                <button
                  type="button"
                  className="ml-4 bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-700"
                  onClick={onClose}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default TeamRegisterPopover;
