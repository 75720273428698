import React from "react";
import { motion } from "framer-motion";
import { ArrowUpRight, Clock, User } from "lucide-react";
import Badge from "shared/Badge/Badge";
import { PostPosterType } from "data/types";

interface PostType {
  id: string;
  title: string;
  href: string;
  featuredImage: string;
  desc: string;
  category: string;
  postType: string;
  date?: string;
  author?: string;
  readingTime?: number;
}

interface BlogPostCardProps {
  className?: string;
  post: PostPosterType;
}

const BlogPostCard: React.FC<BlogPostCardProps> = ({ className, post }) => {
  const { id, title, href, featuredImage, desc, category, date, readingTime } =
    post;

  const handleRedirection = () => {
    sessionStorage.setItem("blogId", id);
    window.location.href = href;
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      whileHover={{ y: -5, cursor: "pointer" }}
      transition={{ duration: 0.3 }}
      onClick={handleRedirection}
      className={`group relative overflow-hidden rounded-3xl max-w-md bg-white dark:bg-gray-800 shadow-lg hover:shadow-xl transition-shadow ${className}`}
    >
      {/* Image Container */}
      <motion.div
        className="relative h-64 overflow-hidden"
        whileHover={{ scale: 1.05 }}
        transition={{ duration: 0.3 }}
      >
        <img
          src={featuredImage}
          alt={title}
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />

        {/* Category Badge */}
        <Badge
          name={category}
          className="absolute top-4 left-4 bg-blue-500/90 hover:bg-blue-600 text-white"
        ></Badge>
      </motion.div>

      {/* Content */}
      <div className="p-6 space-y-4">
        {/* Meta info */}
        <div className="flex items-center gap-4 text-sm text-gray-600 dark:text-gray-400">
          {/* {author && (
            <div className="flex items-center gap-1">
              <User className="w-4 h-4" />
              <span>{author}</span>
            </div>
          )} */}
          {readingTime && (
            <div className="flex items-center gap-1">
              <Clock className="w-4 h-4" />
              <span>{readingTime} min read</span>
            </div>
          )}
        </div>

        {/* Title */}
        <motion.h2 className="text-xl font-bold text-gray-900 dark:text-white group-hover:text-blue-600 dark:group-hover:text-blue-400 transition-colors line-clamp-2">
          {title}
        </motion.h2>

        {/* Description */}
        <p className="text-gray-600 dark:text-gray-300 line-clamp-2">{desc}</p>

        {/* Read More Button */}
        <motion.button
          onClick={handleRedirection}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="inline-flex items-center gap-2 text-blue-600 dark:text-blue-400 font-medium group/button"
        >
          Read More
          <motion.span
            initial={{ x: 0 }}
            animate={{ x: 0 }}
            whileHover={{ x: 5 }}
          >
            <ArrowUpRight className="w-4 h-4" />
          </motion.span>
        </motion.button>
      </div>

      {/* Hover Effect Overlay */}
      <motion.div
        initial={{ opacity: 0 }}
        whileHover={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        className="absolute inset-0 bg-gradient-to-t from-blue-500/10 to-transparent pointer-events-none"
      />
    </motion.div>
  );
};

export default BlogPostCard;
