import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { X, Shield, Gavel, ArrowRight } from "lucide-react";

interface SideSelectPopoverProps {
  onClose: () => void;
  caseId: string;
}

const SideSelectPopover: React.FC<SideSelectPopoverProps> = ({
  onClose,
  caseId,
}) => {
  const handleJoinProsecution = async () => {
    sessionStorage.setItem("caseId", caseId);
    sessionStorage.setItem("userSide", "Prosecutor");
    window.location.href = "/pageCourt";
    onClose();
  };

  const handleJoinDefence = async () => {
    sessionStorage.setItem("caseId", caseId);
    sessionStorage.setItem("userSide", "Defendant");
    window.location.href = "/pageCourt";
    onClose();
  };

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };

  const modalVariants = {
    hidden: { 
      scale: 0.5,
      opacity: 0 
    },
    visible: { 
      scale: 1,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    }
  };

  const buttonVariants = {
    initial: { scale: 1 },
    hover: { 
      scale: 1.05,
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 10
      }
    },
    tap: { scale: 0.95 }
  };

  return (
    <AnimatePresence>
      <motion.div
        className="fixed inset-0 flex items-center justify-center bg-black/50 backdrop-blur-sm z-50"
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={overlayVariants}
      >
        <motion.div
          className="bg-gradient-to-br from-white to-purple-50 rounded-2xl p-8 shadow-2xl max-w-md w-full mx-4 relative border-2 border-purple-100"
          variants={modalVariants}
        >
          <motion.button
            whileHover={{ scale: 1.1, rotate: 90 }}
            whileTap={{ scale: 0.9 }}
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
          >
            <X size={24} />
          </motion.button>

          <div className="text-center mb-8">
            <h2 className="text-2xl font-bold text-purple-800 mb-2">
              🎭 Choose Your Role
            </h2>
            <p className="text-gray-600">
              Pick your side and start building your case!
            </p>
          </div>

          <div className="space-y-6">
            <motion.div
              variants={buttonVariants}
              initial="initial"
              whileHover="hover"
              whileTap="tap"
              className="group"
            >
              <button
                onClick={handleJoinDefence}
                className="w-full bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-shadow"
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-4">
                    <div className="bg-white/20 p-3 rounded-lg">
                      <Shield className="w-6 h-6" />
                    </div>
                    <div className="text-left">
                      <h3 className="font-bold text-lg">Defense Attorney</h3>
                      <p className="text-blue-100 text-sm">Protect the accused</p>
                    </div>
                  </div>
                  <ArrowRight className="w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
                </div>
              </button>
            </motion.div>

            <motion.div
              variants={buttonVariants}
              initial="initial"
              whileHover="hover"
              whileTap="tap"
              className="group"
            >
              <button
                onClick={handleJoinProsecution}
                className="w-full bg-gradient-to-r from-purple-500 to-purple-600 text-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-shadow"
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-4">
                    <div className="bg-white/20 p-3 rounded-lg">
                      <Gavel className="w-6 h-6" />
                    </div>
                    <div className="text-left">
                      <h3 className="font-bold text-lg">Prosecutor</h3>
                      <p className="text-purple-100 text-sm">Seek justice</p>
                    </div>
                  </div>
                  <ArrowRight className="w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
                </div>
              </button>
            </motion.div>
          </div>

          <div className="mt-6 text-center">
            <p className="text-sm text-gray-500">
              💡 Tip: Both roles are crucial in ensuring justice. Choose the one that
              interests you most!
            </p>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default SideSelectPopover;
