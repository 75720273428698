import React, { FC, useEffect, useState, useRef } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { apiUri } from "../../constants";
import LoadingIcon from "components/Loading/LoadingIcon";
import BlogPostCard from "./BlogPostCard";
import { PostPosterType } from "data/types";
import { ChevronLeft, ChevronRight } from "lucide-react";

interface SectionLatestPostsProps {
  posts?: PostPosterType[];
  className?: string;
}

const BlogRow: FC<{
  title: string;
  emoji: string;
  posts: PostPosterType[];
}> = ({ title, emoji, posts }) => {
  const rowRef = useRef<HTMLDivElement>(null);

  const scroll = (direction: "left" | "right") => {
    if (rowRef.current) {
      const scrollAmount = direction === "left" ? -400 : 400;
      rowRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  if (posts.length === 0) return null;

  return (
    <div className="mb-12">
      {/* Category Header */}
      <div className="flex justify-between items-center mb-4 px-4">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center gap-2">
          <span>{emoji}</span>
          <span>{title}</span>
        </h2>
        {/* <button className="text-blue-600 hover:underline text-sm">
          View All
        </button> */}
      </div>

      {/* Scrollable Row */}
      <div className="relative group">
        <button
          onClick={() => scroll("left")}
          className="absolute left-0 top-1/2 -translate-y-1/2 z-10 bg-white/80 dark:bg-gray-800/80 
                   rounded-full p-2 shadow-lg opacity-0 group-hover:opacity-100 transition-opacity 
                   hover:bg-white dark:hover:bg-gray-800"
        >
          <ChevronLeft className="w-6 h-6" />
        </button>

        <div
          ref={rowRef}
          className="flex overflow-x-auto gap-4 px-4 pb-4 scroll-smooth hide-scrollbar"
        >
          {posts.map((post) => (
            <div key={post.id} className="flex-none w-[300px]">
              <BlogPostCard post={post} />
            </div>
          ))}
        </div>

        <button
          onClick={() => scroll("right")}
          className="absolute right-0 top-1/2 -translate-y-1/2 z-10 bg-white/80 dark:bg-gray-800/80 
                   rounded-full p-2 shadow-lg opacity-0 group-hover:opacity-100 transition-opacity 
                   hover:bg-white dark:hover:bg-gray-800"
        >
          <ChevronRight className="w-6 h-6" />
        </button>
      </div>
    </div>
  );
};

const SectionLatestPostsRenderer: FC<SectionLatestPostsProps> = ({
  posts = [],
  className = "",
}) => {
  const categories = [
    { id: "Education", name: "Legal Education", emoji: "📚" },
    { id: "Commentary", name: "Expert Commentary", emoji: "💭" },
    { id: "Rants", name: "Legal Rants", emoji: "😂" },
    { id: "Cases", name: "Case Analysis", emoji: "⚖️" },
    { id: "Technology", name: "Legal Tech", emoji: "💻" },
  ];

  return (
    <div className={`${className} py-8`}>
      <div className="max-w-[1400px] mx-auto">
        {/* Featured Posts */}
        <BlogRow title="Trending Now" emoji="🔥" posts={posts.slice(0, 5)} />

        {/* Category Rows */}
        {categories.map((category) => (
          <BlogRow
            key={category.id}
            title={category.name}
            emoji={category.emoji}
            posts={posts.filter((post) => post.category === category.id)}
          />
        ))}
      </div>
    </div>
  );
};

function SectionLatestPosts() {
  const [details, setDetails] = useState<PostPosterType[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await axios.post(`${apiUri}/fetchAllBlogs`);
        setDetails(response.data.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, []);

  if (loading) {
    return <LoadingIcon text="Fetching all Blogs" />;
  } else if (details) {
    return <SectionLatestPostsRenderer posts={details} />;
  } else {
    return <p>Error loading blog.</p>;
  }
}

export default SectionLatestPosts;
