import { DEMO_POSTS } from "data/posts";
// import { PostDataType } from "data/types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
// import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import LoadingIcon from "components/Loading/LoadingIcon";
import axios from "axios";
import { apiUri } from "../../constants";
import { Bookmark, ChevronLeft, Clock, Share2 } from "lucide-react";

interface DocumentRendererProps {
  documentString: string;
}

const DocumentRenderer: React.FC<DocumentRendererProps> = ({ documentString }) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: documentString }}
      className="nc-SingleContent container space-y-10" 
    />
  );
};



interface BlogSingleProps {
index: string,
    id: string,
  featuredImage: string,
  titleImage: string,
    title: string,
    desc: string,
  date: string,
  contentInHtml: string,
  href: "/blog-single",
  authorDesc: string,
  authorName: string,
  category:string,
  readingTime: number,
  tags: string[],
  featuredImageCaption:string,
    postType: string
}

const BlogSingleRenderer: React.FC<BlogSingleProps> = ({
  featuredImage,
  contentInHtml,
  title,
  desc,
  date,
  readingTime,
  titleImage,
  tags,
  featuredImageCaption,
  authorName,
  category,
  authorDesc,
}) => {
  const [scrollProgress, setScrollProgress] = useState(0);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  let lastScroll = 0;

  useEffect(() => {
    const handleScroll = () => {
      // Calculate scroll progress
      const winScroll = document.documentElement.scrollTop;
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrolled = (winScroll / height) * 100;
      setScrollProgress(scrolled);

      // Handle header visibility
      const currentScroll = window.scrollY;
      setIsHeaderVisible(currentScroll < lastScroll || currentScroll < 100);
      lastScroll = currentScroll;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="relative min-h-screen bg-creamyYellow dark:bg-gray-900">
      {/* Progress bar */}
      <div
        className="fixed top-0 left-0 h-1 bg-blue-600 dark:bg-blue-500 z-50 transition-all duration-300"
        style={{ width: `${scrollProgress}%` }}
      />

      {/* Floating Header */}
      <header
        className={`fixed top-0 w-full bg-white/80 dark:bg-gray-800/80 backdrop-blur-md shadow-sm transition-transform duration-300 z-40 ${
          isHeaderVisible ? "translate-y-0" : "-translate-y-full"
        }`}
      >
        <div className="container mx-auto px-4 py-3 flex items-center justify-between">
          <Link
            to="/blog"
            className="flex items-center space-x-2 text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white"
          >
            <ChevronLeft className="w-5 h-5" />
            <span>Back to Articles</span>
          </Link>
          {/* <div className="flex items-center space-x-4">
            <button className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors">
              <Bookmark className="w-5 h-5" />
            </button>
            <button className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors">
              <Share2 className="w-5 h-5" />
            </button>
          </div> */}
        </div>
      </header>

      <main className="container mx-auto px-4 pt-20">
        {/* Article Header */}
        <div className="max-w-4xl mx-auto mb-10 space-y-6 animate-fade-in">
          <div className="flex items-center space-x-3">
            <Badge name={category} />
           
            <div className="flex items-center text-sm text-gray-500 dark:text-gray-400">
              <Clock className="w-4 h-4 mr-1" />
              <span>{readingTime} min read</span>
            </div>
          </div>

          <h1 className="text-4xl md:text-5xl font-serif font-bold text-gray-900 dark:text-white leading-tight">
            {title}
          </h1>

          <p className="text-xl text-gray-600 dark:text-gray-300">{desc}</p>

          <div className="flex items-center justify-between py-4 border-y border-gray-200 dark:border-gray-700">
            <div className="flex items-center space-x-4">
              <div className="flex flex-col">
                <span className="text-sm text-gray-500 dark:text-gray-400">
                  Written by
                </span>
                <span className="font-medium text-gray-900 dark:text-white">
                  {authorName}
                </span>
              </div>
            </div>
            <span className="text-gray-500 dark:text-gray-400">{date}</span>
          </div>
        </div>

        {/* Featured Image */}
        <div className="max-w-4xl mx-auto mb-12 animate-fade-in">
          <NcImage
            className="w-full rounded-lg shadow-lg"
            src={titleImage}
            alt={title}
          />
          {featuredImageCaption && (
            <p className="mt-2 text-sm text-center text-gray-500 dark:text-gray-400">
              {featuredImageCaption}
            </p>
          )}
        </div>

        {/* Content */}
        <div className="max-w-4xl mx-auto">
          {/* Sidebar */}

          {/* Main Content */}
          <article className="w-full bg-white dark:bg-gray-800 rounded-lg shadow-sm p-8 animate-fade-in">
            <div className="prose prose-lg dark:prose-invert max-w-none">
              <DocumentRenderer
                documentString={contentInHtml
                  .toString()
                  .replace("{featuredImage}", featuredImage)
                  .replace("{featuredImageCaption}", featuredImageCaption)}
              />
            </div>
          </article>
        </div>

        {/* Author Bio */}
        <div className="max-w-4xl mx-auto mt-12 p-8 mb-12  bg-white dark:bg-gray-800 rounded-lg shadow-sm animate-fade-in">
          <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400 uppercase mb-4">
            About the Author
          </h3>
          <div className="flex items-start space-x-4">
            <div className="flex-1">
              <h4 className="text-lg font-semibold text-gray-900 dark:text-white">
                {authorName}
              </h4>
              <p className="mt-2 text-gray-600 dark:text-gray-300">
                {authorDesc}
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};


function BlogSingle() {
  const blogId = sessionStorage.getItem("blogId")
  const [details, setDetails] = useState<BlogSingleProps | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await axios.post(`${apiUri}/fetchBlog`, { id: blogId });
        setDetails(response.data.data);
      } catch (error) {
        console.error('Error fetching case details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [blogId]);

  if (loading) {
    return <LoadingIcon text="Loading Blog"/>;
  } else if (details) {
    return <BlogSingleRenderer {...details} />;
  } else {
    return <p>Error loading blog.</p>;
  }

}

export default BlogSingle;
