import React from "react";
import {
  Scale,
  BookOpen,
  Users,
  MessageSquare,
  VideoIcon,
  Mic,
  Brain,
  Volume2,
  BarChart3Icon
} from "lucide-react";
import { motion } from "framer-motion";

const ComparisonSection = () => {
  const comparisons = [
    {
      title: "Court Practice Sessions",
      traditional: "Limited to scheduled sessions, complex coordination",
      platform: "24/7 access to virtual courtroom simulations",
      icon: <Scale className="w-5 h-5 text-indigo-600" />,
    },
    {
      title: "Moot Court Organization",
      traditional: "Complex logistics and scheduling",
      platform: "Instant access, zero overhead",
      icon: <Users className="w-5 h-5 text-indigo-600" />,
    },
    {
      title: "Performance Analysis",
      traditional: "Generic feedback, limited detail",
      platform: "Comprehensive argument analysis",
      icon: <MessageSquare className="w-5 h-5 text-indigo-600" />,
    },
    {
      title: "Learning Resources",
      traditional: "Theory-heavy approach",
      platform: "True-to-life court scenarios",
      icon: <BookOpen className="w-5 h-5 text-indigo-600" />,
    },
  ];

  const features = [
    {
      title: "Performance Insights",
      description:
        "Get in-depth analysis and scoring on language, themes, and logical structure, with guidance on ideal improvements for impactful conversations.",
      icon: <BarChart3Icon className="w-6 h-6 text-white" />,
      gradient: "from-violet-600 to-indigo-600",
    },
    {
      title: "Video Analysis",
      description:
        "AI-powered analysis of posture, gestures, and presentation style",
      icon: <VideoIcon className="w-6 h-6 text-white" />,
      gradient: "from-indigo-600 to-blue-600",
    },
    {
      title: "Speech Recognition",
      description:
        "Real-time speech-to-text for pronunciation and clarity improvement",
      icon: <Mic className="w-6 h-6 text-white" />,
      gradient: "from-blue-600 to-cyan-600",
    },
    {
      title: "Legal AI Assistant",
      description:
        "Fine-tuned AI model for legal argument validation and improvement",
      icon: <Brain className="w-6 h-6 text-white" />,
      gradient: "from-cyan-600 to-teal-600",
    },
    {
      title: "Voice Modulation",
      description:
        "Analysis of pace, tone, and vocal variety during presentations",
      icon: <Volume2 className="w-6 h-6 text-white" />,
      gradient: "from-teal-600 to-emerald-600",
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15,
      },
    },
  };

  const itemVariants = {
    hidden: {
      opacity: 0,
      y: 30,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: [0.215, 0.61, 0.355, 1.0], // Cubic bezier for smooth animation
      },
    },
  };

  const featureVariants = {
    hidden: {
      opacity: 0,
      x: 50,
      scale: 0.95,
    },
    visible: {
      opacity: 1,
      x: 0,
      scale: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  return (
    <div className="min-h-screen py-10 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          variants={{
            hidden: { opacity: 0, y: -20 },
            visible: { opacity: 1, y: 0 },
          }}
          className="text-center mb-12"
        >
          <h2 className="text-4xl font-bold text-gray-900 p-4  bg-gradient-to-r from-indigo-600 to-blue-600 bg-clip-text text-transparent">
            Transform Your Legal Education
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Advanced tools for modern legal professionals
          </p>
        </motion.div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Main Comparison Section */}
          <motion.div
            className="lg:col-span-2"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            variants={containerVariants}
          >
            <div className="bg-gradient-to-br from-purple-100/80 to-blue-100 backdrop-blur-sm rounded-2xl shadow-xl  border border-gray-100">
              <div className="grid grid-cols-1 gap-8 p-8">
                {comparisons.map((item, index) => (
                  <motion.div
                    key={index}
                    variants={itemVariants}
                    className="group"
                  >
                    <div className="flex items-center gap-4 mb-4">
                      <div className="bg-indigo-100 p-3 rounded-xl group-hover:bg-indigo-200 transition-colors">
                        {item.icon}
                      </div>
                      <h3 className="font-semibold text-xl text-gray-900">
                        {item.title}
                      </h3>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                      <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow border border-red-100">
                        <p className="text-lg font-semibold text-red-800 mb-2">
                          Traditional Approach
                        </p>
                        <p className="text-gray-700">{item.traditional}</p>
                      </div>
                      <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow border border-emerald-100">
                        <p className="text-lg font-semibold text-emerald-800 mb-2">
                          Our Platform
                        </p>
                        <p className="text-gray-700">{item.platform}</p>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </motion.div>

          {/* Features Section */}
          <motion.div
            className="grid grid-cols-1 gap-4 content-start"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.1 }}
            variants={containerVariants}
          >
            {features.map((feature, index) => (
              <motion.div
                key={index}
                variants={featureVariants}
                className={`bg-gradient-to-r ${feature.gradient} rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 cursor-pointer`}
              >
                <div className="flex items-center gap-4 mb-3">
                  <div className="p-2.5 bg-white/10 rounded-lg">
                    {feature.icon}
                  </div>
                  <h3 className="text-lg font-semibold text-white">
                    {feature.title}
                  </h3>
                </div>
                <p className="text-white/90 text-sm leading-relaxed">
                  {feature.description}
                </p>
              </motion.div>
            ))}
          </motion.div>
        </div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: { opacity: 1, y: 0, transition: { delay: 0.3 } },
          }}
          className="mt-16 text-center"
        >
          <button className="px-8 py-3 rounded-full bg-gradient-to-r from-purple-600 to-blue-600 text-white font-medium hover:shadow-lg transform hover:scale-105 transition-all">
            Start Free Trial
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default ComparisonSection;
